<template lang="pug">
div
  van-row(type="flex" justify="center")
    van-col
      h3 {{$store.state.companyInfo.com_name}}
  van-form(@submit='onSubmit')
    van-field(v-model='username', name='username', label='用户名', placeholder='请输入手机号', :rules="[{ required: true, message: '请输入正确的手机号' }]")
    van-field(v-model='password', type='password', name='password', label='密码', placeholder='密码', :rules="[{ required: true, message: '请输入密码' }]")
    van-row.row(type="flex" justify="space-between")
      van-col.col
        van-checkbox(v-model='checked') 记住密码
      van-col.col
        router-link(:to="{name: 'forgetpassword'}") 忘记密码
    van-row.row
      van-button.col(type='info' native-type='submit' block round :disabled='commitState') 登录
      van-button.col(type='info' native-type='button' plain block  round :to="{name: 'quicklogin'}") 手机快捷登录
      van-button.col(type='default' native-type='button' block  round :to="{name: 'register'}") 注册
</template>

<script>
import {LoginCallBack} from '@/lib/helpers/Token'
export default {
  data() {
    return {
      commitState: false,
      username: '',
      password: '',
      checked: false
    }
  },
  methods: {
    async onSubmit(values) {
      try {
        this.commitState = true
        let res = await this.$api.Login(values)
        // console.log(this.$router.app.$route)
        // this.$router.push(this.$router.app.$route)
        await LoginCallBack(this, res.data)
        this.$toast('登录成功')
      } catch (e) {
        this.$toast(this.$error(e))
      }
      this.commitState = false
    }
  },
  mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0px 10px;
}
.col {
  margin: 10px 0px;
}
</style>